import React from 'react'

import { SEO } from '../components/seo'
import { Button } from '../components/button'
import { Link } from '../components/link'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="container container--grid">
      <div className="container__row">
        <div style={{ textAlign: 'center', paddingTop: 80, paddingBottom: 80 }}>
          <div className=" wysiwyg">
            <h1>404: Page not found</h1>
            <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
          </div>
          <Button as={Link} to="/">
            Take me home
          </Button>
        </div>
      </div>
    </div>
  </>
)

export default NotFoundPage
